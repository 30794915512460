import { type Pinia } from 'pinia'
export default defineNuxtPlugin(nuxtApp => {
    // called right before setting a new locale
    nuxtApp.hook('i18n:beforeLocaleSwitch', ({ oldLocale, newLocale, initialSetup, context }) => {
        // maybe emit an event
        const piniaContext = nuxtApp.$pinia as Pinia
        const store = useRoutesStore(piniaContext)
        store.resetRoutes()
    })

    // called right after a new locale has been set
    nuxtApp.hook('i18n:localeSwitched', ({ oldLocale, newLocale }) => {
    })
})