import { toRef } from 'vue'
import type { AdyenRoute } from '../types'

export const useRoutesUtils = () => {
  async function fetchRoutes(targetSite: string = 'all', locale?: string) {
    const routesEndpoint = targetSite === 'all' ? `/api/v3/core_routes/all` : `/api/v3/${targetSite}_routes`
    const data = await $fetch(routesEndpoint, { query: { locale } }) as AdyenRoute[]
    return data
  }

  function isHome(path: string) {
    return (
      path === '' ||
      path === '/' ||
      /^\/[a-z]{2}_[A-Z]{2}\/{0,1}$/.test(path)
    )
  }

  /**
   * Sanitize route path without tailing slash
   */
  function sanitizeRoutePath(path: string) {
    return isHome(path) ? path : path.replace(/\/+$/, '')
  }

  /**
   * Remove section name from route path
   * The section name corresponds to first path for default locale and 2nd path for other locale
   */
  const removeSectionNameFromRoute = (locale: string, path: string) => {
    const itemPosition = locale === 'en' ? 1 : 2
    try {
      const paths = path?.split('/')
      paths.splice(itemPosition, 1)
      return paths.join('/')
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * Get path regexp to be used for the generate process to exclude entire sections
   * @param {String} path - website url path
   * @returns {RegExp} path regexp expected by the nuxt generate config
   */
  const getRoutePathRegex = path => new RegExp(`^\\${path}`)

  /**
   * Filter and map the routes for a targetSite for the client side router
   * @param {Array} pages
   * @param {String} targetSite
   * @returns {Array} routes and paths for a given target site
   */
  const updateAndFilterRoutePaths = (pages, targetSite) => {
    // For server side path should not be updated
    // if (process.server) {
    //   return routes
    // }

    // Update route path for client side router
    return pages
      .filter(route => route?.name?.startsWith(targetSite))
      .map(route => ({
        ...route,
        path:
          removeSectionNameFromRoute(
            route.path.startsWith(`/${targetSite}`) ? 'en' : '',
            route.path
          ) || '/'
      }))
  }

  /**
   * For a given target site, update the route for client side router
   * by removing the `targetSite` from the path
   * @param {Array} pages
   * @param {String} targetSite
   * @returns {Array} mapped pages and routes
   */
  const updateNuxtRoutes = (pages: AdyenRoute[], targetSite: string) => {
    // For server side path should not be updated
    // if (process.server) {
    //   return routes
    // }

    // Update route path for client side router
    return pages
      .map(page => ({
        ...page,
        route:
          removeSectionNameFromRoute(
            page.route.startsWith(`/${targetSite}`) ? 'en' : '',
            page.route
          ) || '/'
      }))
      .filter(Boolean)
  }

  const wrapLocale = (_loc: string) => {
    return toRef(_loc)
  }

  const getLayerRoutePath = (routePath: String) => {
    if (!routePath || routePath.trim() === '' || routePath.trim() === '/') {
      return ''
    }
    return routePath.endsWith('/') ? routePath.slice(-2) : routePath
  }

  return {
    isHome,
    fetchRoutes,
    wrapLocale,
    updateNuxtRoutes,
    updateAndFilterRoutePaths,
    removeSectionNameFromRoute,
    getRoutePathRegex,
    sanitizeRoutePath,
    getLayerRoutePath
  }
}
