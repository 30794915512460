import { defineStore } from 'pinia'
import type { AdyenRoute } from '~/types'

export const useRoutesStore = defineStore('routes-adyen', () => {
  const routes: Ref<AdyenRoute[]> = ref([])
  const allRoutes: Ref<AdyenRoute[]> = ref([])

  const { fetchRoutes } = useRoutesUtils()
  const { locale } = useI18n()
  const targetSite = useRuntimeConfig().public.targetSite

  const retrieveRoutes = async () => {
    // TODO: On language switch, clear
    if (routes.value.length > 0) {
      return
    }

    const _routes = await fetchRoutes(targetSite, locale.value)
    routes.value = _routes
    return _routes
  }

  const resetRoutes = () => {
    routes.value = []
  }

  const retrieveAllRoutes = async () => {
    // TODO: On language switch, clear
    if (allRoutes.value.length > 0) {
      return
    }

    const _routes = await fetchRoutes('all', locale.value)
    allRoutes.value = _routes
    return _routes
  }

  return { retrieveAllRoutes, retrieveRoutes, resetRoutes, routes, allRoutes }
})
